import './set-public-path.js';
import moment from 'moment-timezone';
import { redRockDate } from './redRockDate';

// helper function that pre-applies the correct parse string for you
moment.redRockDate = redRockDate;

// support unified shell chinese (simplified) locale
const globalLocale = moment.locale();
moment.defineLocale('zh-hans', { parentLocale: 'zh-cn' });
moment.defineLocale('zh-hant', { parentLocale: 'zh-tw' });
moment.locale(globalLocale);

/*
 * Exporting everything from moment so that code that does the following still works
 * import * as moment from 'moment'
 */

// eslint-disable-next-line import/export
export * from 'moment';
export default moment;
